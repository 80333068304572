import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbDropdownModule, NgbAccordionModule, NgbNavModule, NgbProgressbarModule, NgbTooltipModule, NgbPopoverModule, NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { PagesRoutingModule } from './pages-routing.modules';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganizationComponent } from './dashboards/organization/organization.component';
import { TimekeeperDashboardComponent } from './dashboards/timekeeper-dashboard.component';
import { LpcDashboardComponent } from './dashboards/lpc-dashboard/lpc-dashboard.component';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { UsersGridComponent } from './users-grid/users-grid.component';
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from 'primeng/calendar';
import { MattersComponent } from './dashboards/matters/matters.component';
import { TaskGridComponent } from './tasks-grid/task-grid.component';
import { MessageService } from 'primeng/api';
import { TkAvailabilityComponent } from './tk-availability/tk-availability/tk-availability.component';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { TaskCatalogueComponent } from './task-catalogue/task-catalogue.component';
import { ProjectsTemplateComponent } from './projects-template/projects-template.component';
import { ProjectsGridComponent } from './projects-grid/projects-grid.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ViewOrganizationComponent } from './dashboards/organization/view-organization/view-organization.component';
import { TabViewModule } from "primeng/tabview";
import { ViewMatterComponent } from './dashboards/matters/view-matter/view-matter.component';
import { ImportDataComponent } from './../import-data/import-data.component';
import { FileUploadModule } from 'primeng/fileupload';
import { TimeRecordGridComponent } from './time-record-grid/time-record-grid.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TimeRecordExportComponent } from './time-record-export/time-record-export.component';
import { ErrorComponent } from './error/error.component';
import { AppConfigComponent } from './app-config/app-config.component';
import { MinutesToTimePipe } from '../shared/pipes/minutesToString.pipe';
import { PayrollTimeReportComponent } from './payroll-time-report/payroll-time-report.component';
import { ClientTimeReportComponent } from './client-time-report/client-time-report.component';
import { TasksGridViewComponent } from './tasks-grid-view/tasks-grid-view.component';
import { TimeApproverRulesComponent } from './time-approver-rules/time-approver-rules.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);


@NgModule({
  declarations: [
    OrganizationComponent,
    TimekeeperDashboardComponent,
    LpcDashboardComponent,
    ImportDataComponent,
    UsersGridComponent,
    MattersComponent,
    TaskGridComponent,
    LoadingSpinnerComponent,
    TkAvailabilityComponent,
    TaskCatalogueComponent,
    ProjectsTemplateComponent,
    ProjectsGridComponent,
    ViewOrganizationComponent,
    ViewMatterComponent,
    TimeRecordGridComponent,
    TimeRecordExportComponent,
    ErrorComponent,
    AppConfigComponent,
    MinutesToTimePipe,
    PayrollTimeReportComponent,
    ClientTimeReportComponent,
    TasksGridViewComponent,
    TimeApproverRulesComponent
  ],
  imports: [
    CommonModule,
    FullCalendarModule,
    TableModule,
    CalendarModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    PagesRoutingModule,
    ScrollToModule.forRoot(),
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE'
    }),
    DialogModule,
    ButtonModule,
    NgbModalModule,
    TabViewModule,
    FileUploadModule,
    RadioButtonModule
  ],
  providers:[ DatePipe]
})
export class PagesModule { }
