import { Time } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { helperService } from 'src/app/utils/helper';
import { TimeRecord } from '../dashboards/timeRecord.model';
import { TimeRecordService } from './time-record.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthGuard } from 'src/app/account/auth/app.gaurd';
import { MessageService, SelectItem } from 'primeng/api';
import Swal from 'sweetalert2';
import { matchModeOptionsDateList, timeRecord } from 'src/app/utils/filterDropDownData';
import { Table } from 'primeng/table';
import { AppConfigService } from '../app-config/app-config.service';
import { UsersGridService } from '../users-grid/users-grid.service';

@Component({
  selector: 'app-time-record-grid',
  templateUrl: './time-record-grid.component.html',
  styleUrls: ['./time-record-grid.component.scss']
})
export class TimeRecordGridComponent implements OnInit {
  @Input()
  dashboard: boolean = false;

  @Input()
  tasksgridPrjFilter: any = false;

  @Input() 
  tkdashboard:any = false;

  @Input()
  lpcdashboard:any = false;

  selectedRows: TimeRecord[] = [];
  timeRecords: TimeRecord[] = [];
  cols: any = [];
  userrole: any;
  totalRecords: number = 0;
  first: number = 0;
  private lastTableLazyLoadEvent: any = null;
  showTrDialog: Boolean = false;
  loading: Boolean = false;
  timeForm: any = {};
  lpcadminflag: boolean = false;
  showRejectField: boolean = false;
  filterOptions=timeRecord.filters;
  selectedFilterOption: string='All Time Records';
  title: String = "";
  acceptedDates:any;
  datefutureValid:boolean=true;
  viewonlyTR: boolean = false;
  billableList:any;
  timeRecStatusList:any;
  taskStatusList:any;
  rejectionInvalid:boolean=false;
  showrejectComment:boolean=false;
  onlylpcadmin:boolean=false;
  onlytk:boolean=false;
  combroles:boolean=false;
  statuscodes = ['INPROGRESS', 'INREVIEW', 'APPROVED', 'REJECTED']
  rejectComment:string=""
  rejectStandardReasonList=timeRecord.rejectStandardReasonList
  defaultTime: any;
  stateCodeMapList: any;
  timeRecordsDueDate:any;
  timeRecordsStartDate:any;
  projectStartDate:any;
  projectDueDate:any;
  timeClosingDate:any;
  searchTerm:string='';
  lastSearchTerm:any='';
  filterDatesMatchModes:any={taskDueDate:'dateIs',createdOn:'dateIs',date:'dateIs'}
  filterDates:any={taskDueDate:null,createdOn:null,date:null}
  dateFilters:any=[]
  matchModeOptionsDate: SelectItem[] =[
    { label: 'Date Is', value: 'dateIs' },
    { label: 'Date Before', value: 'ltDate' },
    { label: 'Date After', value: 'gtDate' },
    { label: 'Date Range', value: 'betweenDate' },
  ];


  constructor(private filterService: helperService, private readonly messageService: MessageService, 
    private timeRecordService: TimeRecordService, private auth: AuthGuard,
    private appConfigService:AppConfigService,private cdr:ChangeDetectorRef,private userService:UsersGridService) { }

  ngOnInit(): void {
    if(this.tkdashboard||this.lpcdashboard)
     this.dashboard=true;
    this.userrole = this.auth.roles;
    this.defaultTime = new Date();
    this.defaultTime.setHours(0, 0, 0, 0);
    this.controlactions();
    this.stateCodeMapList=timeRecord.stateCodeMapList;
    this.cols = [
      { field: 'organizationName', header: 'Organization Name' },
      { field: 'matterName', header: 'Matters Name' },
      { field: 'projectName', header: 'Project Name' }, 
      { field: 'taskName', header: 'Task Name' },
      { field: 'taskId', header: 'Task Id' },
      { field: 'stateCode', header: 'State' },
      { field: 'createdBy', header: 'Requested By' },
      { field: 'createdOn', header: 'Created Date' },
      { field: 'taskDueDate', header: 'Due Date' },
      { field: 'isCompleted', header: 'Task Completed' },
      { field: 'taskStatusCode', header: 'Task Status' },
      { field: 'date', header: 'Time Record Date' },
      { field: 'timeRecordStatus', header: 'Time Record Status' },
      { field: 'isBillable', header: 'Billable' },
      { field: 'amountOfTime', header: 'Amount of Time' },
      { field: 'description', header: 'Description' },
      { field: 'tkComment', header: 'Time Record Comments' },
      { field: 'rejectReason', header: 'Rejection Reason' }

    ];
    this.taskStatusList=timeRecord.taskStatusList;
    this.billableList=timeRecord.billableList;
    this.timeRecStatusList=timeRecord.timeRecStatusList;

    this.timeForm = new FormGroup({
      id: new FormControl(''),
      taskName: new FormControl(''),
      taskId:new FormControl(''),
      projectName: new FormControl(''),
      organizationName: new FormControl(''),
      matterName: new FormControl(''),
      qbTaskId: new FormControl(''),
      timeEstimate: new FormControl(''),
      actualTime: new FormControl(''),
      isEnforceMaximum: new FormControl(''),
      projectStartDate: new FormControl(''),
      stateCode: new FormControl(''),
      country: new FormControl(''),
      isCompleted: new FormControl(''),
      isBillable: new FormControl(''),
      includeToday: new FormControl(''),
      consolidatedTimeRecords: new FormControl(''),
      isRequireEstimate: new FormControl(''),
      statusCode: new FormControl(''),
      isApprovalRequired: new FormControl(''),
      taskDueDate: new FormControl(''),
      projectDueDate: new FormControl(''),
      openEnded: new FormControl(''),
      quantityState: new FormControl([]),
      quantityNumber: new FormControl(''),
      quantity: new FormControl(''),
      quantityType: new FormControl(''),
      quantityRelated: new FormControl(''),
      maxTime: new FormControl(''),
      rateTypeCode: new FormControl(''),
      isStateRelated: new FormControl(''),
      countryRelated: new FormControl(''),
      createdOn: new FormControl(''),
      dueDaySlot: new FormControl(''),
      enteredTime: new FormControl(''),
      approver: new FormControl(''),
      approved: new FormControl(''),
      description: new FormControl(''),
      timeRecordStatus: new FormControl(''),
      taskCompleted: new FormControl(''),
      timeRecordDueDate: new FormControl(''),
      date:new FormControl(''),
      loggedTime: new FormControl(''),
      rejectComment: new FormControl(''),
      rejectReason: new FormControl(''),
      stateDesc:new FormControl(''),
      tkComment:new FormControl(''),
      taskAssignmentId:new FormControl('')
    });

    
    this.cdr.detectChanges();
  }
  

  controlactions() {

    // if (this.userrole.includes("LPC") || this.userrole.includes("ADMIN")) {
    //   this.lpcadminflag = true;
    // }
    if (this.filterService.isOnlyTKUser(this.userrole)) {
      this.onlytk=true;
      return;
    }
    if (this.userrole.includes("LPC") || this.userrole.includes("ADMIN")) {
      // this.lpcadminflag = true;
      if(this.userrole.includes("TK")){
        this.combroles=true;
      }
      else{
        this.onlylpcadmin=true;
      }
    }
  }
  convertTime(time: any) {
    const date = new Date();
    const [hours, minutes] = (String(Math.floor(time / 60)) + ':' + String(time % 60)).split(':');
    date.setHours(Number(hours), Number(minutes));
    return date
  }

  loadTimeData(event: any,table?:Table) {
    this.lastTableLazyLoadEvent = event;
    this.loading = true;
    if(table){
      table._first=0;
      event.first =  1;
      event.rows =  10;
    }else{
      event.first = event.first ? event.first : 1;
      event.rows = event.rows ? event.rows : 10;
    }
    let payload = this.filterService.generateEventPayload(event)
    // payload.filters.push({"field":"timeRecStatus","operator":"notEquals","value":"In Progress"})
    this.timeRecords = [];
    this.appConfigService.getConfigurationsById(null).subscribe(res=>{
      let data=res.data.filter((obj:any)=>obj.name=="Time Closing Date");
      // if(new Date(data[0].value)>new Date()){
      //   this.configDueDate=new Date();
      // }else{
      //   this.configDueDate=data[0]?new Date(data[0].value):new Date();
      // }
      this.timeClosingDate=data.length>0 &&data[0].value?new Date(data[0].value):new Date();
    })
    if(this.selectedFilterOption=='My Time Records'){
      payload["category"]="My Time Records";
      payload.filters.push(
        {
          "field": "createdBy",
          "operator": "equals",
          "value": this.auth.jwtData.email,
          "logicGrp":"OR"
        }
      );
    }
    else if(this.selectedFilterOption=='All Time Records'){
      payload["category"]="All Time Records";
    }

    if(this.tasksgridPrjFilter){
      payload.filters.push({"field":"projectName","operator":"contains","value":this.tasksgridPrjFilter});
    }
    if(this.dateFilters){
      payload.filters=payload.filters.concat(this.dateFilters)
    }
    if(this.searchTerm){
      event.first =1
    }
    if(this.lastSearchTerm!=this.searchTerm){
      event.first =1
    }
    this.lastSearchTerm=this.searchTerm;

    payload["searchTerm"]=this.searchTerm;
    this.timeRecordService.getTimeRecordsList(Math.floor(event.first / event.rows + 1), event.rows, this.filterService.useDashboardUrl(this.auth.roles,this.tkdashboard),payload).subscribe(res => {
      
      this.userService.getLoginUser().subscribe(loggedUser => {
        console.log(loggedUser);
        loggedUser = loggedUser.rows;
        let list = res.data;
        let tempRecords: TimeRecord[] = [];
        list.forEach((element: any) => {
          let obj: any = {}
          obj["organizationName"] = element.alProjectTask.orgName;
          obj["matterName"] = element.alMatter.name;
          obj["projectName"] = element.alProject.name;
          obj["taskName"] = element.alProjectTask.taskName;
          obj["taskId"] = element.alProjectTask.id;
          obj["quantityType"] = element.alProjectTask.quantityType;
          obj["quantityRelated"] = element.alProjectTask.quantityRelated;
          obj["createdBy"] = element.createdBy;
          obj["quantity"] = element.quantity;
          obj["taskAssignmentId"]=element.taskAssignmentId;
          obj["quantityState"]=element.quantity && element.alProjectTask.quantityType?.toUpperCase()=="STATE"?element.quantity.split(","):[];
          obj["taskDueDate"] = element.alProjectTask.taskDueDate;
          obj["createdOn"] = element.createdOn;
          obj["taskStatusCode"] = element.alProjectTask.statusCode;
          obj["isBillable"] = element.alProjectTask.isBillable;
          obj['includeToday'] = element.alProjectTask.includeToday;
          var hours = Math.floor(element.enteredTime / 60);
          var minutes = element.enteredTime % 60;
          let date = new Date();
          date.setHours(hours);
          date.setMinutes(minutes);
          obj["enteredTime"] = date;
          obj["amountOfTime"] = element.enteredTime
          obj["description"] = element.description;
          obj["date"]=element.date;
          // obj["timeRecordDueDate"] = new Date(element.date);
          obj["timeRecordStatus"] = element.timeRecStatus;
          obj["taskCompleted"] = element.alProjectTask.isCompleted ;
          obj["isCompleted"]= element.alProjectTask.isCompleted;
          obj["id"] = element.id;
          obj['loggedTime'] = this.convertTime(element.alProjectTask.timeRecordsLoggedTime);
          obj['maxTime'] = this.convertTime(element.alProjectTask.maxTime);
          obj['timeEstimate'] = this.convertTime(element.alProjectTask.timeEstimate);
          obj['isRequireEstimate'] = element.alProjectTask.isRequireEstimate;
          obj['dueDaySlot'] = element.alProjectTask.dueDaySlot ? element.alProjectTask.dueDaySlot : '-';
          // obj['rejectComment'] = element.rejectComment ? element.rejectComment : '-';
          obj['rejectComment'] = element.rejectComment;
          obj['rejectReason'] = element.rejectReason;
          obj['tkComment']=element.tkComment;
          obj['isEnforceMaximum']=element.alProjectTask.isEnforceMaximum;
          obj['acceptedDates']=element.alProjectTask.acceptedDates;
          obj['stateDesc']=element.alProjectTask.stateDesc;
          obj['openEnded']=element.alProjectTask.openEnded;
          obj['stateCode']=element.alProjectTask.stateCode;
          obj['projectStartDate']=element.alProject.startDate ? new Date(element.alProject.startDate):null;
          obj['projectDueDate']=element.alProject.dueDate ? new Date(element.alProject.dueDate):null;
          obj['disableAcceptRejectFlag']=true;
          obj['disableEditFlag']=false;
         
          if((obj.timeRecordStatus=='In Review') && loggedUser?.approvePracticeAreaCodes?.filter((description:any)=> description==element.alProjectTask.practiceAreaCode).length>0){
            obj['disableAcceptRejectFlag']=false
          }
          
          if((obj.timeRecordStatus=='In Review') && loggedUser?.approveUsersId?.includes(element.alUser.id)){
            obj['disableAcceptRejectFlag']=false
          }
          
          if(element.alProject.closedDate && this.filterService.isPastDate(new Date(element.alProject.closedDate))){
            obj['disableAcceptRejectFlag']=true
          }

          if(this.userrole.includes("ADMIN")){
            obj['disableAcceptRejectFlag']=false
          }

          if(obj.timeRecordStatus!='In Progress'){
            obj['disableEditFlag']=true
          }

          tempRecords.push(obj);
        });
        this.timeRecords = tempRecords;
        this.loading = false;
        this.totalRecords = res.customValues.totalNumberOfRecords;
        this.cdr.detectChanges();
      })
    })
  }
  closeDialog() {
    this.viewonlyTR = false;
    this.showTrDialog = false;
    this.loadTimeData(this.lastTableLazyLoadEvent);
    this.cdr.detectChanges();
  }


  openViewTimeRecord(timeRecord: any) {
    timeRecord.includeToday=this.filterService.getflagBoolean(timeRecord.includeToday);
    timeRecord.isBillable=this.filterService.getflagBoolean(timeRecord.isBillable);
    timeRecord.taskCompleted=this.filterService.getflagBoolean(timeRecord.taskCompleted);
    timeRecord.isRequireEstimate=this.filterService.getStrBoolean(timeRecord.isRequireEstimate);
    timeRecord.isEnforceMaximum=this.filterService.getflagBoolean(timeRecord.isEnforceMaximum);
    timeRecord.isCompleted=this.filterService.getflagBoolean(timeRecord.isCompleted);
    
    this.showTrDialog = true;
    this.viewonlyTR=true;
    this.acceptedDates=timeRecord.acceptedDates;
    this.title = "Time Record : " + timeRecord.taskName
    this.timeForm.patchValue(timeRecord);
    // this.timeForm.controls['isBillable'].value = timeRecord.isBillable == "No" ? false : true;
    // this.timeForm.controls['isCompleted'].value = timeRecord.isCompleted== "No" ? false : true; 
    // this.timeForm.controls['includeToday'].value = timeRecord.includeToday == "No" ? false : true;
    this.timeForm.disable();
    if(timeRecord.rejectComment!=null){
      this.showrejectComment=true;
    }
  }
  openEditTimeRecord(timeRecord: any) {
    timeRecord.includeToday=this.filterService.getflagBoolean(timeRecord.includeToday);
    timeRecord.isBillable=this.filterService.getflagBoolean(timeRecord.isBillable);
    timeRecord.taskCompleted=this.filterService.getflagBoolean(timeRecord.taskCompleted);
    timeRecord.isRequireEstimate=this.filterService.getStrBoolean(timeRecord.isRequireEstimate);
    timeRecord.isEnforceMaximum=this.filterService.getflagBoolean(timeRecord.isEnforceMaximum);
    timeRecord.isCompleted=this.filterService.getflagBoolean(timeRecord.isCompleted);
    this.showTrDialog = true;
    this.title = "Time Record : " + timeRecord.taskName
    this.timeForm.patchValue(timeRecord);
    this.timeForm.disable();
    this.viewonlyTR = false;
    this.acceptedDates=timeRecord.acceptedDates;
    // this.timeForm.controls['isBillable'].value = timeRecord.isBillable
    this.timeForm.controls['isBillable'].enable();
    this.timeForm.controls['taskCompleted'].enable();
    this.timeForm.controls['timeRecordDueDate'].enable();
    this.timeForm.controls['date'].enable();
    this.timeForm.controls['enteredTime'].enable();
    this.timeForm.controls['rejectComment'].enable();
    this.timeForm.controls['rejectReason'].enable();
    this.timeForm.controls['quantity'].disable();
    this.timeForm.controls['description'].enable();
    this.timeForm.controls['quantityType'].enable();
    this.projectStartDate=timeRecord.projectStartDate;
    this.projectDueDate=timeRecord.projectDueDate;
    this.timeRecordsStartDate=timeRecord.projectStartDate;
    if(this.timeForm.controls["rejectComment"].value!=null){
      this.showrejectComment=true;
    }
    if(this.timeForm.controls['quantityType'].value=="Number"){
      this.timeForm.controls['quantity'].enable();
    }else{
      this.timeForm.controls['quantityState'].enable();
    }
    this.timeForm.controls['tkComment'].enable();
      //quantity enable based on task qyantityType
    this.validateTrRange()
  }

  validateTrRange(){
    const timeclosingDate=new Date(this.timeClosingDate);
    const today=new Date()

    let trstart=timeclosingDate;
    let trend=this.projectDueDate;

    if(timeclosingDate.getTime()<this.projectStartDate.getTime()){
      trstart=this.projectStartDate;
    }

    if(this.projectDueDate.getTime()>today.getTime()){
      trend=today;
    }
    
    this.timeRecordsStartDate=trstart
    this.timeRecordsDueDate=trend
    console.log(this.timeRecordsStartDate)
    console.log(this.timeRecordsDueDate)
  }

  openApproveTimeRecord(timeRecord: any) {
    
    timeRecord.includeToday=this.filterService.getflagBoolean(timeRecord.includeToday);
    timeRecord.isBillable=this.filterService.getflagBoolean(timeRecord.isBillable);
    timeRecord.taskCompleted=this.filterService.getflagBoolean(timeRecord.taskCompleted);
    timeRecord.isRequireEstimate=this.filterService.getStrBoolean(timeRecord.isRequireEstimate);
    timeRecord.isEnforceMaximum=this.filterService.getflagBoolean(timeRecord.isEnforceMaximum);
    timeRecord.isCompleted=this.filterService.getflagBoolean(timeRecord.isCompleted);
    this.showTrDialog = true;
    this.title = "Time Record : " + timeRecord.taskName
    this.acceptedDates=timeRecord.acceptedDates;
    this.timeForm.patchValue(timeRecord);
    this.timeForm.disable();
    // this.timeForm.controls['description'].enable();
    this.timeForm.controls['rejectComment'].enable();
    this.timeForm.controls['rejectReason'].enable();
  }

  updateTR(status: any) {
    let timeFormObj = this.timeForm.getRawValue();
    // let time = timeFormObj.enteredTime.getHours() * 60 + timeFormObj.enteredTime.getMinutes() +timeFormObj.loggedTime.getHours()*60+timeFormObj.loggedTime.getMinutes();
    let time= this.filterService.convertDateToTime(timeFormObj.enteredTime);
    let existingTaskLogTime= this.filterService.convertDateToTime(timeFormObj.loggedTime);
    let maxTime = timeFormObj.maxTime ? timeFormObj.maxTime.getHours() * 60 + timeFormObj.maxTime.getMinutes() :
      timeFormObj.timeEstimate.getHours() * 60 + timeFormObj.timeEstimate.getMinutes();
    this.datefutureValid = new Date(timeFormObj.date)<=new Date()  ? true : false;
    
    let payload:any = {
      // "date": this.filterService.convertDateToFormattedStr(timeFormObj.timeRecordDueDate),//timeFormObj.timeRecordDueDate,
      "tkComment":timeFormObj.tkComment,
      "date":timeFormObj.date,
      "description": timeFormObj.description,
      "enteredTime": time,
      "billable": this.filterService.getflagBoolean(timeFormObj.isBillable),
      "taskCompleted": this.filterService.getflagBoolean(timeFormObj.taskCompleted),
      "status": this.statuscodes[Number(status)],
      "rejectComment": timeFormObj.rejectComment?timeFormObj.rejectComment:"",
      "rejectReason": timeFormObj.rejectReason?timeFormObj.rejectReason:"",
      "taskAssignmentId":timeFormObj.taskAssignmentId
    };
    if(timeFormObj.quantityType=="Number"){
      if(((typeof timeFormObj.quantity=="string" && isNaN(timeFormObj.quantity)) 
            ||!timeFormObj.quantity 
            || timeFormObj.quantity<=0) 
          && timeFormObj.quantityRelated=='Yes'){
        this.messageService.add({ severity: 'error', detail: 'Please enter valid Quantity number (should be greater than zero)!' });
        return;
      }else{
        payload['quantity']=timeFormObj.quantity
      }
    }else{
      if((!timeFormObj.quantityState || timeFormObj.quantityState.length<=0) && timeFormObj.quantityRelated=='Yes'){
        this.messageService.add({ severity: 'error', detail: 'Please select atleast one Quantity state!' });
        return;
      }else{
        payload['quantity']=timeFormObj.quantityState.join(",")
      }
    }
    
    // if(this.datefutureValid){
    if (timeFormObj.isEnforceMaximum){
    if (maxTime && time <= maxTime || this.filterService.getStrBoolean(timeFormObj.openEnded)=="Yes") {
      this.timeRecordService.updateTR(payload, this.timeForm.controls.id.value).subscribe(res => {
        if (res.message == "Success.") {
          this.closeDialog();
        }
        else{
          this.messageService.add({ severity: 'error', detail: res.message });
        }
      }, res => {
        this.messageService.add({ severity: 'error', detail: res.error.message });
      });
    }
    else {
      this.messageService.add({ severity: 'error', detail: 'The total logged time must not exceed the max time or estimated time.' });
    }}
    else{
      this.timeRecordService.updateTR(payload, this.timeForm.controls.id.value).subscribe(res => {
        if (res.message == "Success.") {
          this.closeDialog();
        }else{
          this.messageService.add({ severity: 'error', detail: res.message });
        }
      }, res => {
        this.messageService.add({ severity: 'error', detail: res.error.message });
      });
    }
  // }
  // else{
  //   this.messageService.add({ severity: 'error', detail: 'No future Date allowed!' });

  // }
  }
  approve(approveFlag:any){
    if(!approveFlag){
      if(this.timeForm.controls.rejectComment.value==null || this.timeForm.controls.rejectReason.value==null ||this.timeForm.controls.rejectReason.value==''){
          this.rejectionInvalid=true;
          return;
      } 
    }
    let payload:any={
      "timeRecords" : [
          {
              "timeRecordId" : this.timeForm.controls.id.value,
              "status" : approveFlag?"APPROVED":"REJECTED",
              "description":this.timeForm.controls.description.value,
              "rejectComment" :  this.timeForm.controls.rejectComment.value,
              "rejectReason" :  this.timeForm.controls.rejectReason.value,
              "tkComment" :  this.timeForm.controls.tkComment.value,
              "quantity":this.timeForm.controls.quantityType.value=="Number"?this.timeForm.controls.quantity.value:this.timeForm.controls.quantityState.value.join(",")
          }
      ]
    }
    
    
    this.timeRecordService.submitMultiple(payload).subscribe(res => {
      this.closeDialog();
      Swal.fire(payload.timeRecords[0].status);
      this.selectedRows = [];
      this.loadTimeData(this.lastTableLazyLoadEvent);
    });
  }

  submitMultiple(status: any, rows: any) {
    let list: any[] = []
    let payload = { "timeRecords": list };
    if (rows.length > 0) {
      rows.forEach((val: any) => {
        let obj: any = {};
        obj["timeRecordId"] = val.id;
        obj["status"] = status;
        payload.timeRecords.push(obj);
      })
      this.timeRecordService.submitMultiple(payload).subscribe(res => {
        Swal.fire(status);
        this.selectedRows = [];
        this.loadTimeData(this.lastTableLazyLoadEvent);
      });
    }
  }
  deleteTR(rows: any) {
    let list: any[] = [];
    rows.forEach((val: any) => {
      list.push(val.id);
    })
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        timeRecordIds: list
      },
    };

    this.timeRecordService.deleteTR(options).subscribe(res => {
      this.selectedRows = [];
      this.loadTimeData(this.lastTableLazyLoadEvent);
    });
  }

  rejectionClicked(event: any) {
    this.showRejectField = event.target.checked
  }

  handleTabChange(table:Table,event:any){
    this.loading=true;
    this.timeRecords=[];
    if(event.index==0){
      this.tkdashboard=false;
    }else{
      this.tkdashboard=true;
    }
    table.clear();
    this.loadTimeData(this.lastTableLazyLoadEvent);
  }

  selectAllFilter(){
    this.selectedRows=this.selectedRows.filter((val:any)=>val.timeRecordStatus=='In Progress')
  }

  
onDateFilterChange(field: any) {
  if(this.filterDatesMatchModes[field]=='betweenDate'){
    this.filterDates[field] =null ;
  }
}

clearDatesFilter(field: any) {
  console.log('Clear date filter',field);
  this.filterDates[field] = null;
  this.dateFilters =this.dateFilters.filter((filter:any) => filter.field !== field);
  this.loadTimeData(this.lastTableLazyLoadEvent);
}
applyDatesFilter(field: any) {
  console.log("apply", this.filterDates[field],this.filterDatesMatchModes[field]);
  let dates=this.filterDates[field]
  const index = this.dateFilters.findIndex((filter:any) => filter.field === field);
  if(index>-1){
    this.dateFilters.splice(index,1);
  }
  if(this.filterDatesMatchModes[field]=='betweenDate'){
    this.dateFilters.push({field:field, operator: "betweenDate", value:  this.filterService.convertDateToFormattedPayloadStr(dates[0]),
                          valueTo:this.filterService.convertDateToFormattedPayloadStr(dates[1])});
  }else{
    this.dateFilters.push({field:field, operator: this.filterDatesMatchModes[field], value:  this.filterService.convertDateToFormattedPayloadStr(dates)});
  }
  this.loadTimeData(this.lastTableLazyLoadEvent);
}

clearAllFilters(table: Table) { 
  this.dateFilters = [];
  Object.keys(this.filterDatesMatchModes).map((field:any)=>this.filterDatesMatchModes[field]='')
  Object.keys(this.filterDates).map((field:any)=>this.filterDatesMatchModes[field]='')
  table.clear();
}

onDateSelect(field: any,event: any) {
  if(this.filterDatesMatchModes[field]=='betweenDate' && this.filterDates[field].length==2 && this.filterDates[field][0] && this.filterDates[field][1]){
    const calendarElement=document.querySelector('.p-calendar');
    if(calendarElement){
     const overlay=document.querySelector('.p-datepicker');
     if(overlay)
      (overlay as HTMLElement).style.display='none';
    }
  }
}

}
