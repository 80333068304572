import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { helperService } from 'src/app/utils/helper';
import { ProjectTemplateService } from '../projects-template/project-template.service';
import { TaskGridService } from '../tasks-grid/task-grid.service';
import { ProjectsGridService } from './projects-grid.service';
import { AuthGuard } from 'src/app/account/auth/app.gaurd';
import { matchModeOptionsDateList, project } from 'src/app/utils/filterDropDownData';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-projects-grid',
  templateUrl: './projects-grid.component.html',
  styleUrls: ['./projects-grid.component.scss']
})
export class ProjectsGridComponent implements OnInit {
  @Input()
  projectMatterFilter: any = null;

  @Input()
  projectOrgFilter: any = null;

  @Input()
  dashboard: boolean = false;

  @Input() 
  tkdashboard:any = false;

  @Input()
  lpcdashboard:any = false;

  headerflag: boolean = false;

  projects: any[] = [];
  cols: any[] = [];
  projectDialog: boolean = false;
  assignTaskDialogue: boolean = false;
  viewOnly: boolean = false;
  disableSave: boolean = false;
  newProject: boolean = false;
  matchModeOptionsString?: SelectItem[];
  matchModeOptions?: SelectItem[];
  filterDatesMatchModes:any={startDate:'dateIs',closedDate:'dateIs',dueDate:'dateIs'}
  filterDates:any={startDate:null,closedDate:null,dueDate:null}
  dateFilters:any=[]
  matchModeOptionsDate: SelectItem[] =[
    { label: 'Date Is', value: 'dateIs' },
    { label: 'Date Before', value: 'ltDate' },
    { label: 'Date After', value: 'gtDate' },
    { label: 'Date Range', value: 'betweenDate' },
  ];
  matchModeOptionsNumber?: SelectItem[];
  title: string = "";
  selectedProjectName: string = "";
  loading: boolean = false;
  totalRecords: number = 0;
  first: number = 1;
  projectForm: any = {};
  descriptionTypes: any = [];
  practiceAreaCodes: any = [];
  submitted: boolean = false;
  btnLoading: boolean = false;
  disableEdit: boolean = false;
  faloading:boolean=false;
  newTask: any = ""
  selectedProject: any = {}
  projectTemplates = [];
  statuses:any;
  filterOptions=project.filters;
  selectedFilterOption: string='All Projects';
  lpcUsersList = []
  requestedPersons = []
  TKAssignedUser: string = "";
  priorities:any;
  projectTypeCodes = {};
  mattersList = {};
  allOrganisations = [];
  unAssignedTasks = [];
  matterName: String = ""
  orgName: String = "";
  tasksloading:boolean=false;
  assignLoading: boolean = false;
  private lastTableLazyLoadEvent: any = null;
  private lastSelectedStatus: any = null;
  projectdueDate: any;
  defaultTime: any;
  isEnforceEstimatedTimeList:any;
  isBillableList:any;
  confirmMessage:string="";
  showConfirmAlert:Boolean=false;
  projectClosedData:any;
  searchTerm:string='';
  lastSearchTerm:any='';
  confirmationDialogue: Boolean = false;
  confirmationDialogMessage: string = "";
  

  constructor(private datePipe: DatePipe, private projectService: ProjectsGridService, private taskService: TaskGridService,
    private projectTemplateService: ProjectTemplateService, private readonly messageService: MessageService,
    private filterService: helperService, private authService: AuthGuard) { }

  ngOnInit(): void {

    if (this.filterService.isOnlyTKUser(this.authService.roles)||(this.authService.roles.length > 1 && this.tkdashboard ) ) {
      this.disableEdit = true;
    }
    this.defaultTime = new Date();
    this.isEnforceEstimatedTimeList=project.isEnforceEstimatedTimeList;
    this.isBillableList=project.isBillableList;
    this.priorities=project.priorities;
    this.statuses=project.statuses;
    this.defaultTime.setHours(0, 0, 0, 0);
    this.projectForm = new FormGroup({

      name: new FormControl('', [Validators.required]),
      projectTemplateId: new FormControl(''),
      startDate: new FormControl('', [Validators.required]),
      closedDate: new FormControl(''),
      dueDate: new FormControl('', [Validators.required]),
      estimatedTime: new FormControl(''),
      actualTime: new FormControl(''),
      estimatedBudget: new FormControl(''),
      // , [ Validators.pattern('^[0-9]*(\.[0-9]{0,2})?$')]
      isEnforceEstimatedTime: new FormControl('', [Validators.required]),
      isForceRequestedBy: new FormControl(''),
      projectTypeId: new FormControl('', [Validators.required]),
      statusCode: new FormControl('', [Validators.required]),
      priorityCode: new FormControl('', [Validators.required]),
      lpcUserId: new FormControl('', [Validators.required]),
      isBillable: new FormControl('', [Validators.required]),
      notes: new FormControl(''),
      description: new FormControl(''),
      requestedBy: new FormControl(''),
      matterId: new FormControl('', [Validators.required]),
      organizationId: new FormControl('', [Validators.required]),
      organizationName:new FormControl(''),
      matterName:new FormControl(''),
      projectTemplateName:new FormControl(''),
      id: new FormControl({ value: '', disabled: true }),
      actualSpend: new FormControl(''),
      holdTimeUntilCompleted: new FormControl('', [Validators.required]),
      dbProjectStatusCode: new FormControl(''),
      dbProjectholdTimeUntilCompleted: new FormControl(''),
    });

    this.cols = [
      { field: 'name', header: 'Project Name' },
      { field: 'organizationName', header: 'Organization Name' },
      { field: 'matterName', header: 'Matter Name' },
      { field: 'projectTemplateName', header: 'Template Assigned' },
      { field: 'description', header: 'Description' },
      { field: 'holdTimeUntilCompleted', header: 'holdTimeUntilCompleted' },
      { field: 'estimatedTime', header: 'Estimated Time' },
      { field: 'estimatedBudget', header: 'Estimated Budget' },
      { field: 'isEnforceEstimatedTime', header: 'Enforced Estimated Time' },
      { field: 'actualTime', header: 'Actual Time' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'closedDate', header: 'Close Date' },
      { field: 'dueDate', header: 'Due Date' },
      { field: 'projectTypeCode', header: 'Project Type' },
      { field: 'statusCode', header: 'Status' },
      { field: 'priorityCode', header: 'Priority' },
      { field: 'createdOn', header: 'Created Date' },
      { field: 'createdBy', header: 'Created By' },
      { field: 'isBillable', header: 'Billable' },
      { field: 'lpcUserEmail', header: 'LPC' }
    ];

    this.projectTemplateService.getTemplateList(null,null,{"filters":[{"field":"status","operator":"equals","value":"Active"}],"sort":[]},true).subscribe(res => {
      this.projectTemplates = res.data
    });

    this.projectService.getProjectTypesList().subscribe(res => {
      this.projectTypeCodes=res.data.map((template:any) => {
        return {
          id: template.id,
          label: template.description,
          value: template.code,
        };
      });
      
    })

    this.projectService.getLPCUsersList({
      "roles": ["LPC"],
      "userTypes": []
    }).subscribe(res => {
      this.lpcUsersList = res.data;
    })

    this.projectService.getAllMatters({}).subscribe(res => {
      this.mattersList = res.data;
    })

    this.projectService.getAllOrganizations().subscribe(res => {
      this.allOrganisations = res.data;
    })

  }
  loadProjectsData(event: LazyLoadEvent, assignOperation?: Boolean,selectedStatus?:any,table?:Table) {

    
    if(selectedStatus && selectedStatus!=this.lastSelectedStatus){
      event.first =  1;
      event.rows =  10;
      this.lastSelectedStatus=selectedStatus
      this.totalRecords=0;
    }else{
      // this.lastSelectedStatus=selectedStatus
      event.first = event.first ? event.first : 1;
      event.rows = event.rows ? event.rows : 10;
    }
      
    if(table){
      table._first=0;
      event.first =  1;
      event.rows =  10;
    }
    this.lastTableLazyLoadEvent = event;
    if (assignOperation) 
      this.loading = false;
    else
      this.loading=true;
    
   
    let payload = this.filterService.generateEventPayload(event)
    
    if (this.projectMatterFilter) {
      payload.filters.push({
        "field": "matterId",
        "operator": "equals",
        "value": this.projectMatterFilter
      })
    }
    if (this.projectOrgFilter) {
      payload.filters.push({
        "field": "organizationId",
        "operator": "equals",
        "value": this.projectOrgFilter
      })
    }
    if(this.lastSelectedStatus && this.lastSelectedStatus!='Any'){
      payload.filters.push({
        "field": "statusCode",
        "operator": "equals",
        "value": this.lastSelectedStatus
      })
    }

    // this.loading = true;
    if(this.selectedFilterOption=='My Projects'){
      payload["category"]="My Projects";
      payload.filters.push(
        {
          "field": "createdBy",
          "operator": "equals",
          "value": this.authService.jwtData.email,
          "logicGrp":"OR"
        }
      );
      payload.filters.push(
        {
          "field": "lpcUserEmail",
          "operator": "equals",
          "value": this.authService.jwtData.email,
          "logicGrp":"OR"
        }
      )
    }
    else{
      payload["category"]="All Projects";
    }
    if(this.dateFilters){
      payload.filters=payload.filters.concat(this.dateFilters)
    }
    if(this.lastSearchTerm!=this.searchTerm){
      event.first =1
    }
    this.lastSearchTerm=this.searchTerm;

    payload["searchTerm"]=this.searchTerm;
    this.projectService.getProjectsList(Math.floor(event.first / event.rows + 1), event.rows,this.filterService.useDashboardUrl(this.authService.roles,this.tkdashboard), payload).subscribe(res => {
      this.projects = res.data;
      if (this.assignLoading) {
        this.loading = false;
        this.assignLoading = false;
        this.messageService.add({ severity: 'success', detail: 'Task details have been assigned successfully' });
      }
      this.projects.forEach(obj=>{
        obj.actualSpend=obj.actualSpend?(obj.actualSpend).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }):'$0.00';
        obj.estimatedBudget=obj.estimatedBudget?(obj.estimatedBudget).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }):'$0.00';
        obj['disableEditProject']=false;
        obj['dbProjectStatusCode']=obj.statusCode;
        obj['dbProjectholdTimeUntilCompleted']=obj.holdTimeUntilCompleted;
        obj['disableAssignProject']=false;
        if(obj.statusCode!='In Progress' && obj.statusCode!='On Hold'){
          obj['disableEditProject']=true;
          obj['disableAssignProject']=true;
        }
        if(obj.closedDate && this.filterService.isPastDate(new Date(obj.closedDate))){
          obj['disableEditProject']=true;
          obj['disableAssignProject']=true;
        }
        else if(this.filterService.isPastDate(new Date(obj.dueDate))){
          obj['disableEditProject']=true;
          obj['disableAssignProject']=true;
        }
        if(this.filterService.isAdminUser(this.authService.roles)||this.filterService.isLPCUser(this.authService.roles)){
          obj['disableEditProject']=false;
        }
      })
      this.totalRecords = res.customValues.totalNumberOfRecords;
      if (this.selectedProject.id) {
        this.selectedProject = this.projects.filter((obj: any) => obj.id == this.selectedProject.id)[0];
        this.transformTasks(this.selectedProject);
      }
      this.loading = false;
    })
  }
  addProject() {
    this.projectDialog = true;
    this.newProject = true;
    this.viewOnly = false;
    this.title = "Add Project";
    this.projectForm.reset();
    let x:any={};
    x['statusCode']="In Progress";
    this.projectForm.patchValue(x);
    this.projectForm.enable();
    // this.projectForm.controls['actualTime'].disable()
    // this.projectForm.controls['estimatedTime'].disable()

  }
  getAllMatter(event: any) {
    this.faloading=true;
    let payload = {
      "filters": [{
        "field": "orgId",
        "operator": "equals",
        "value": Number(event.split(" ")[1])
      }], "sort": []
    };
    this.projectService.getAllMatters(payload).subscribe(res => {
      this.mattersList = res.data;
      this.faloading=false;
    })
  }
  viewProject(projects: any) {
    this.projectForm.patchValue(projects);
    this.projectForm.controls['estimatedTime'].value = this.filterService.convertTimeNumToStr(this.projectForm.controls['estimatedTime'].value)
    this.projectForm.controls['actualTime'].value = this.filterService.convertTimeNumToStr(this.projectForm.controls['actualTime'].value)
    this.projectForm.disable();
    this.viewOnly = true;
    this.projectDialog = true;
    this.title = "View Project";
  }

  editProject(projects: any) {
    this.faloading=true;
    // this.requestedPersons = projects.requestedPersons;
    this.projectForm.patchValue(projects);
    this.projectForm.enable();
    this.projectService.getAllMatters({
      "filters": [{
        "field": "orgId",
        "operator": "equals",
        "value": projects.organizationId
      }], "sort": []
    }).subscribe(res => {
      this.mattersList = res.data;
      this.faloading=false;
    })
    this.projectService.getAllRequestedUsers(projects.organizationId).subscribe(res=>{
      this.requestedPersons=res &&res.data?res.data:[];
    })
    this.projectForm.controls['startDate'].value =this.projectForm.controls['startDate'].value? new Date(this.projectForm.controls['startDate'].value):null
    this.projectForm.controls['dueDate'].value = this.projectForm.controls['dueDate'].value?new Date(this.projectForm.controls['dueDate'].value):null
    this.projectdueDate=this.projectForm.controls['dueDate'].value
    this.projectForm.controls['closedDate'].value = this.projectForm.controls['closedDate'].value?new Date(this.projectForm.controls['closedDate'].value): null;
    this.projectForm.controls['estimatedTime'].value = this.filterService.convertTimeNumToStr(this.projectForm.controls['estimatedTime'].value)
    this.projectForm.controls['actualTime'].value = this.filterService.convertTimeNumToStr(this.projectForm.controls['actualTime'].value)
    // this.projectForm.controls['organizationId'].disable()
    // this.projectForm.controls['matterId'].disable()
    this.projectForm.controls['projectTemplateId'].disable()
    // this.projectForm.controls['closedDate'].disable()
    this.projectForm.controls['estimatedTime'].disable()
    this.projectForm.controls['actualTime'].disable()
    this.projectClosedData=this.projectForm.controls['closedDate'].value 
    this.projectDialog = true;
    this.title = "Edit Project";
    this.faloading=false;
  }

  closeDialog() {
    this.projectDialog = false;
    this.newProject = false;
    this.viewOnly = false;
    this.submitted = false;
    this.disableSave = false;
    this.btnLoading=false
    this.loadProjectsData(this.lastTableLazyLoadEvent);
    this.messageService.clear();
    this.faloading=false;
  }
  saveProject(saveBtnClicked?:any,confirmed?:any){ 
    this.projectForm.value.startDate = this.datePipe.transform(this.projectForm.value.startDate, "MM/dd/yyyy")
    this.projectForm.value.dueDate = this.datePipe.transform(this.projectForm.value.dueDate, "MM/dd/yyyy")
    this.projectForm.value.closedDate = this.projectForm.value.closedDate?this.datePipe.transform(this.projectForm.value.closedDate, "MM/dd/yyyy"):null
    // this.projectForm.value.estimatedTime = this.filterService.convertDateToTime(this.projectForm.value.estimatedTime)
    // this.projectForm.value.actualTime = this.filterService.convertDateToTime(this.projectForm.value.actualTime)
    // this.projectForm.value.matterName = "TEST MATTER"
    // this.projectForm.value.organizationName = "TEST ORGANIZATION"
    // this.projectForm.value.projectTemplateName="TEST TEMPLATE NAME"

    // if(saveCloseDate==false){
    //   this.showConfirmAlert=false;
    //   this.closeDialog()
    //   return;
    // }

    
      
    if (this.btnLoading )
      return;

    
    this.submitted = true;
    if (this.projectForm.valid) {
      if(saveBtnClicked&& this.projectForm.value.holdTimeUntilCompleted=="Yes" && this.projectForm.value.statusCode=="Completed"){
        this.confirmMessage="You are Completing a Project marked as Hold Time Until Completed. By Completing this Project, all Time will be released and invoiced to the Client. Are you sure you want to Complete this Project?";
        this.showConfirmAlert=true;
        return;
      }
      if(saveBtnClicked&& this.projectForm.value.holdTimeUntilCompleted=="Yes" && this.projectForm.value.dbProjectStatusCode=="Completed" && this.projectForm.value.statusCode=="In Progress"){
        this.confirmMessage=`You are changing the status of a Completed Project back to In Progress for a Project marked as Hold Time Until Completed.All Time Records have already been released and invoiced for this Project. 
                            By changing the status back to In Progress, only Time Records submitted from this date forward will be held until this Project is marked Completed. Are you sure you want to change the status of this Project?
                            `;
        this.showConfirmAlert=true;
        return;
      }
      if(saveBtnClicked&& this.projectForm.value.holdTimeUntilCompleted=="No" && this.projectForm.value.dbProjectholdTimeUntilCompleted=="Yes" ){
        this.confirmMessage=`You are changing Hold Time Until Completed to No. All existing Records will be released and invoiced for this Project. Are you sure you want to change Hold Time Until Completed for this Project? `;
        this.showConfirmAlert=true;
        return;
      }
      
      if(confirmed==false){
        this.showConfirmAlert=false;
        this.closeDialog();
        return;
      }
      // if((this.projectForm.value.closedDate && !saveCloseDate) && (this.projectClosedData==null ||this.filterService.compareDate(this.projectClosedData,new Date(this.projectForm.value.closedDate))!=0)){
        if(this.projectForm.value.closedDate){
        this.btnLoading = true;
        try {
          let id=this.projectForm.controls['id'].value
          if(id){
            this.projectService.getAllPendingItems(id).subscribe(
              res => {
                let str=""
                if(res.data.pendingTask.length ){
                  str=str+"This Project has  "+res.data.pendingTask.length+" pending Tasks "
                }
                if(res.data.pendingTimerecords.length ){
                  if(res.data.pendingTask.length)
                    str=str+"and "+res.data.pendingTimerecords.length+" pending TimeRecords. "
                  else
                    str=str+"This Project has "+res.data.pendingTimerecords.length+" pending TimeRecords. "
                }
                if(str!=""){
                  str=str+"Please complete pending items before closing this Project."
                  this.btnLoading = false;
                  this.disableSave = true;
                  this.showConfirmAlert=false;
                  this.messageService.add({ severity: 'error', detail: str });
                }else{
                  this.persistProjectData();
                }
                
              }, res => {
                this.btnLoading = false;
                  this.showConfirmAlert=false;
                  this.messageService.add({ severity: 'error', detail: res.error.message });
              })
          }else{
            this.persistProjectData();
          }
        } catch (error: any) {
          this.btnLoading = false;
          console.log(error)
          this.showConfirmAlert=false;
          this.messageService.add({ severity: 'error', detail: error.message });
        }
      }else{
        this.persistProjectData();
      }
      

    }
  }

  persistProjectData(){
    this.btnLoading = true;
    const id = this.projectForm.controls['id'].value
    if (!this.projectForm.controls['id'].value)
      delete this.projectForm.value.id
    const payload = this.projectForm.value
    payload.actualSpend=null;
    // this.projectForm.getRawValue().actualSpend?Number(this.projectForm.getRawValue().actualSpend.replace("/[^0-9.-]+/g","")):null;
    payload.estimatedBudget=null;
    payload.holdTimeUntilComp = payload.holdTimeUntilCompleted ;
    delete payload.dbProjectStatusCode
    delete payload.dbProjectholdTimeUntilCompleted
    this.showConfirmAlert=false;
    this.projectForm.disable();
    try {
      this.projectService.saveProject(payload, id).subscribe(
        res => {
          this.btnLoading = false;
          if (res.message) {
            this.disableSave = true;
            this.submitted = false;
            this.messageService.add({ severity: 'success', detail: 'Project ' + this.projectForm.controls['name'].value + ' details have been saved successfully' });
          } else {
            this.projectForm.enable();
            this.projectForm.controls['actualTime'].disable()
            this.messageService.add({ severity: 'error', detail: res.message });
          }
          this.showConfirmAlert=false;
          }, res => {
          this.btnLoading = false;
          this.projectForm.enable();
          this.projectForm.controls['actualTime'].disable()
          this.messageService.add({ severity: 'error', detail: res.error.message });
        })
    } catch (error: any) {
      this.btnLoading = false;
      this.projectForm.enable();
      this.projectForm.controls['actualTime'].disable()
      console.log(error)
      this.messageService.add({ severity: 'error', detail: error.message });
    }
  }

  get name() {
    return this.projectForm.get('name');
  }
  get projectTemplateId() {
    return this.projectForm.get('projectTemplateId');
  }
  get startDate() {
    return this.projectForm.get('startDate');
  }
  get closedDate() {
    return this.projectForm.get('closedDate');
  }
  get dueDate() {
    return this.projectForm.get('dueDate');
  }
  get estimatedTime() {
    return this.projectForm.get('estimatedTime');
  }
  get actualTime() {
    return this.projectForm.get('actualTime');
  }
  get estimatedBudget() {
    return this.projectForm.get('estimatedBudget');
  }
  get isEnforceEstimatedTime() {
    return this.projectForm.get('isEnforceEstimatedTime');
  }
  get projectTypeId() {
    return this.projectForm.get('projectTypeId');
  }
  get statusCode() {
    return this.projectForm.get('statusCode');
  }

  get priorityCode() {
    return this.projectForm.get('priorityCode');
  }
  get lpcUserId() {
    return this.projectForm.get('lpcUserId');
  }
  get isBillable() {
    return this.projectForm.get('isBillable');
  }
  get notes() {
    return this.projectForm.get('notes');
  }
  get description() {
    return this.projectForm.get('description');
  }
  get requestedBy() {
    return this.projectForm.get('requestedBy');
  }
  get matterId() {
    return this.projectForm.get('matterId');
  }
  get organizationId() {
    return this.projectForm.get('organizationId');
  }
  get holdTimeUntilCompleted() {
    return this.projectForm.get('holdTimeUntilCompleted');
  }
  get dbProjectStatusCode() {
    return this.projectForm.get('dbProjectStatusCode');
  }

  openAssignTask(project: any) {
    this.title = "Assign New Task"
    this.orgName = project.organizationName;
    this.matterName = project.matterName;
    this.selectedProjectName = project.name;
    this.assignTaskDialogue = true;
    this.transformTasks(project);
    
    
  }

  transformTasks(project: any) {
   
    this.tasksloading=true;
    this.projectService.getProjectUnassignedTasksList(project.id).subscribe(res=>{
      project.unAssignedTasks=res.data.unAssignedTasks;
      project.projectTasks=res.data.projectTasks;
      
    
    for (let k = 0; k < project.unAssignedTasks.length; k++) {
      let str = "";
      let taskFullName = project.unAssignedTasks[k].taskFullName? project.unAssignedTasks[k].taskFullName: project.unAssignedTasks[k].taskName;
      if(taskFullName){
        for (let j = 1; j < taskFullName.split(":").length; j++) {
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
        }
        project.unAssignedTasks[k].taskName = str + project.unAssignedTasks[k].taskName;
        project.unAssignedTasks[k].disabled=project.unAssignedTasks[k].isHeader=="Yes"?true:false;
        project.unAssignedTasks[k].description=project.unAssignedTasks[k].description?project.unAssignedTasks[k].description:"No Description";
      }
    }
    this.unAssignedTasks = project.unAssignedTasks
    this.selectedProject = project
    this.tasksloading=false;
  });
  }
  

  closeAssignTaskDialogue() {
    this.assignTaskDialogue = false;
    this.selectedProject.id=null;
    this.loadProjectsData(this.lastTableLazyLoadEvent);
  }

  assignTask() {
    this.messageService.clear();
    const payload = {
      projectId: this.selectedProject.id,
      taskIds: [this.newTask.id]
    }
    if (this.assignLoading) return;
    this.assignLoading = true;
    try {
      this.taskService.assignTask(payload).subscribe(
        res => {
          if (res.message) {
            this.newTask = "";
            this.loadProjectsData(this.lastTableLazyLoadEvent, true);

          } else {
            this.assignLoading = false;
            this.messageService.add({ severity: 'error', detail: res.message });
          }
        }, res => {
          this.assignLoading = false;
          console.log(res.error)
          this.messageService.add({ severity: 'error', detail: res.error.message });
        })
    } catch (error: any) {
      this.assignLoading = false;
      console.log(error)
      this.messageService.add({ severity: 'error', detail: error.message });
    }
  }

  deleteTask(task: any) {
    if (this.assignLoading) return;
    this.assignLoading = true;
    try {
      this.taskService.deleteTask(this.selectedProject.id, task.id).subscribe(
        res => {
          this.loadProjectsData(this.lastTableLazyLoadEvent, true);
          if (res.message) {
            // this.messageService.add({ severity: 'success', detail: 'Task details have been deleted successfully' });
          } else {
            // this.taskForm.enable();
            this.assignLoading = false;
            this.messageService.add({ severity: 'error', detail: res.message });
          }
        }, res => {
          this.assignLoading = false;
          // this.taskForm.enable();
          console.log(res.error)
          this.messageService.add({ severity: 'error', detail: res.error.message });
        })
    } catch (error: any) {
      this.assignLoading = false;
      // this.taskForm.enable();
      console.log(error)
      this.messageService.add({ severity: 'error', detail: error.message });
    }
  }

  onDateFilterChange(field: any) {
    this.filterDates[field]=null
  }

  clearDatesFilter(field: any) {
    console.log('Clear date filter',field);
    this.filterDates[field] = null;
    this.dateFilters =this.dateFilters.filter((filter:any) => filter.field !== field);
    this.loadProjectsData(this.lastTableLazyLoadEvent);
  }
  applyDatesFilter(field: any) {
    console.log("apply", this.filterDates[field],this.filterDatesMatchModes[field]);
    let dates=this.filterDates[field]
    const index = this.dateFilters.findIndex((filter:any) => filter.field === field);
    if(index>-1){
      this.dateFilters.splice(index,1);
    }
    if(this.filterDatesMatchModes[field]=='betweenDate'){
      this.dateFilters.push({field:field, operator: "betweenDate", value:  this.filterService.convertDateToFormattedPayloadStr(dates[0]),
                            valueTo:this.filterService.convertDateToFormattedPayloadStr(dates[1])});
    }else{
      this.dateFilters.push({field:field, operator: this.filterDatesMatchModes[field], value:  this.filterService.convertDateToFormattedPayloadStr(dates)});
    }
    this.loadProjectsData(this.lastTableLazyLoadEvent);
  }

  clearAllFilters(table: Table) { 
    this.dateFilters = [];
    Object.keys(this.filterDatesMatchModes).map((field:any)=>this.filterDatesMatchModes[field]='')
    Object.keys(this.filterDates).map((field:any)=>this.filterDates[field]=null)
    table.clear();
  }

  onDateSelect(field: any,event: any) {
    if(this.filterDatesMatchModes[field]=='betweenDate' && this.filterDates[field].length==2 && this.filterDates[field][0] && this.filterDates[field][1]){
      const calendarElement=document.querySelector('.p-calendar');
      if(calendarElement){
       const overlay=document.querySelector('.p-datepicker');
       if(overlay)
        (overlay as HTMLElement).style.display='none';
      }
    }
  }





}