import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeApproverRulesService {
  private readonly listUsersForApprovalUrl = environment.main_url + environment.listUsersForApproval
  private readonly updateUsersForApprovalUrl = environment.main_url + environment.updateUsersForApproval

  constructor(private readonly http: HttpClient) { }

  getUsersForApproval(pageNumber: any, pageSize: any, payload: any) {
    return this.http.post<any>(this.listUsersForApprovalUrl + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize, payload)
  }

  updateMapping(payload: any) {
    return this.http.post<any>(this.updateUsersForApprovalUrl, payload)
  }

}
