import { Component, Input, OnInit, Output } from '@angular/core';
import { FilterMatchMode, LazyLoadEvent, SelectItem } from 'primeng/api';
import { User } from '../dashboards/user.model';
import { UsersGridService } from './users-grid.service';
import { helperService } from 'src/app/utils/helper';
import { userTimeZones } from 'src/app/utils/userData';


@Component({
  selector: 'app-users-grid',
  templateUrl: './users-grid.component.html',
  styleUrls: ['./users-grid.component.scss']
})
export class UsersGridComponent implements OnInit {
 
  users: User[]=[];
  cols: any[] =[];
  userDialog: boolean=false;
  viewOnly: boolean=false;
  user: any={};
  newUser:boolean=false;
  matchModeOptionsString?: SelectItem[];
  matchModeOptionsDate?: SelectItem[];
  matchModeOptionsNumber?: SelectItem[];
  title:string="";
  loading:boolean = true;
  totalRecords:number=0;
  first:number=1;
  timeZones:any=[]

  private lastTableLazyLoadEvent: any=null;
  constructor( private userService : UsersGridService,private filterService: helperService) { }

  ngOnInit(): void {
    // this.users=userDetails;
      this.timeZones=userTimeZones;

      this.cols = [
            { field: 'firstName', header: 'First Name' },
            { field: 'lastName', header: 'Last Name' },
            { field: 'mainEmail', header: 'Main Email' },
            { field: 'isActive', header: 'Is Active' },
            { field: 'finCenId', header: 'Fincen Id' },
            { field: 'phoneNumber', header: 'Main Phone' },
            { field: 'timeZoneCode', header: 'Time Zone' },
            { field: 'roleCodes', header: 'Role' },
            { field: 'userTypeCode', header: 'Type' },
            { field: 'tkPracticeArea', header: 'Tk Practice Area' },
            { field: 'pmPracticeArea', header: 'Pm Practice Area' },
            { field: 'approver', header: 'Approver' },
            // { field: 'autoApprover', header: 'is Auto Approver' },
        ];

        // this.states=userDetails.map(v=>v.state);
  }

  editUsers(user:any):void{
    // console.log(user);
    // console.log(this.users);
    this.user=user;
    this.userDialog=true;
    this.viewOnly=false;
    this.newUser=false;
    this.title="Edit User";
  }
  viewUsers(user:any):void{
    this.user=user;
    this.userDialog=true;
    this.viewOnly=true;
    this.newUser=false;
    
    this.title="View User";
    
  }
  closeDialog():void{
    this.userDialog=false;
    this.loadUserData(this.lastTableLazyLoadEvent);
  }

  viewProfile(user: any) {
    this.user=user;
    this.userDialog=true;
    this.viewOnly=true;
    this.newUser=false;
    this.title="Profile Details";
  }

  addUser():void{
    this.user={};
    this.userDialog=true;
    this.viewOnly=false;
    this.newUser=true;
    this.title="Add User";
  }

  loadUserData(event: LazyLoadEvent){
    this.lastTableLazyLoadEvent = event;
    this.loading= true;
    
    event.first=event.first?event.first:1;
    event.rows=event.rows?event.rows:10;
    let payload=this.filterService.generateEventPayload(event)
    this.userService.getUsersList(Math.floor(event.first / event.rows + 1), event.rows,payload).subscribe(res => {
      this.users = res.rows;
      this.users.map((obj:any)=>{
        obj.isActive=this.filterService.getStrBoolean(obj.isActive);
      })
      this.loading = false;
      this.totalRecords=res.customValues.totalNumberOfRecords;
    })
  }
  InActivateUser(user:any){
    console.log(user)
    let paylaod=JSON.parse(JSON.stringify(user));
    paylaod.isActive=!this.filterService.getflagBoolean(user.isActive);
    this.loading = true;
    this.userService.inactivateUser(paylaod).subscribe(res => {
      this.loading = false;
      this.loadUserData(this.lastTableLazyLoadEvent);
    }, error => {
    })
  }

  saveUser():void{
  }

}
