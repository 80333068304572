import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { User } from 'src/app/core/models/auth.models';
import { helperService } from 'src/app/utils/helper';
import { TimeApproverRulesService } from './time-approver-rules.service';
import { UsersGridService } from '../users-grid/users-grid.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectsGridService } from '../projects-grid/projects-grid.service';

@Component({
  selector: 'app-time-approver-rules',
  templateUrl: './time-approver-rules.component.html',
  styleUrls: ['./time-approver-rules.component.scss']
})
export class TimeApproverRulesComponent implements OnInit {

  users: User[] = [];
  userForm: any = {};
  cols: any[] = [];
  userDialog: boolean = false;
  viewOnly: boolean = false;
  matchModeOptionsString?: SelectItem[];
  matchModeOptionsDate?: SelectItem[];
  matchModeOptionsNumber?: SelectItem[];
  title: string = "";
  loading: boolean = true;
  btnLoading: boolean = false;
  totalRecords: number = 0;
  first: number = 1;
  tkusersList: any[] = []
  practiceAreas: any[] = []
  private lastTableLazyLoadEvent: any = null;
  gridErrorMessage: string = "";
  approverList: any[] = []
  approverPracticeAreaList: any[] = []

  constructor(private projectService: ProjectsGridService,
    private userService: UsersGridService,
    private filterService: helperService,
    private timeApproverRulesService: TimeApproverRulesService,
    private readonly messageService: MessageService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'mainEmail', header: 'Main Email' },
      { field: 'timeZoneCode', header: 'Time Zone' },
      { field: 'roleCodes', header: 'Role' },
      { field: 'userTypeCode', header: 'Type' },
      { field: 'approvePracticeAreaCodes', header: 'Tk Practice Area' },
      { field: 'approveUsersEmailIds', header: 'Pm Practice Area' },
      // { field: 'autoApprover', header: 'is Auto Approver' },
    ];

    this.userForm = new FormGroup({
      id: new FormControl(''),
      approveUsers: new FormControl([]),
      approverPracticeArea: new FormControl([]),
      mainEmail: new FormControl('')
    });

    this.userService.getPracticeArea().subscribe(res => {
      this.practiceAreas = res.data;
    })

    this.projectService.getLPCUsersList({
      "roles": ["TK"]
    }).subscribe(res => {
      this.tkusersList = res.data;
    })
  }

  editUsers(user: any): void {
    this.userDialog = true;
    this.viewOnly = false;
    this.title = "Edit User";
    let userObj = JSON.parse(JSON.stringify(user));
    userObj.approveUsers = user.approveUsersId;
    userObj.approverPracticeArea = user.approvePracticeAreaCodes;
    this.approverList=this.tkusersList.filter(x=>user.approveUsersId.includes(x.id))
    this.approverPracticeAreaList=user.approvePracticeAreaCodes
    this.userForm.patchValue(userObj);
  }

  viewUsers(user: any): void {
    this.userDialog = true;
    this.viewOnly = true;
    let userObj = JSON.parse(JSON.stringify(user));
    userObj.approveUsers = user.approveUsersId;
    userObj.approverPracticeArea = user.approvePracticeAreaCodes;
    this.userForm.patchValue(userObj);
    this.approverList=this.tkusersList.filter(x=>user.approveUsersId.includes(x.id))
    this.approverPracticeAreaList=user.approvePracticeAreaCodes
    this.title = "View User";
  }

  closeDialog(): void {
    this.userDialog = false;
    this.loadUserData(this.lastTableLazyLoadEvent);
  }

  loadUserData(event: LazyLoadEvent) {
    this.lastTableLazyLoadEvent = event;
    this.loading = true;

    event.first = event.first ? event.first : 1;
    event.rows = event.rows ? event.rows : 10;
    let payload = this.filterService.generateEventPayload(event);
    console.log(payload.filters)
    payload.filters.push({ "field": "roleCodes", "operator": "in", "values": ["Approver"] }, {"field":"isDeleted","operator":"equals","value":0});
    console.log(payload.filters)
    this.timeApproverRulesService.getUsersForApproval(Math.floor(event.first / event.rows + 1), event.rows, payload).subscribe(res => {
      this.users = res.rows;
      this.loading = false;
      this.totalRecords = res.customValues.totalNumberOfRecords;
      this.users.map((obj:any)=>{
        obj.isActive=this.filterService.getStrBoolean(obj.isActive);
        obj.approveUsersEmailIds=obj.approveUsersEmailIds.join(", ");
        obj.approverPracticeAreaCodes=obj.approverPracticeAreaCodes.join(", ");
      })
    }, res => {
      this.loading = false;
      this.users = []
      this.gridErrorMessage = res?.error?.message && res?.error?.status!=500 ? res.error.message : "Error while fetching Data..!";
    })
  }

  updateUserRoleMapping() {
    this.messageService.clear();
    this.btnLoading=true;
    let payload:any={}
    payload.approveUsersId=this.userForm.getRawValue().approveUsers;
    payload.approvePracticeAreaCodes=this.userForm.getRawValue().approverPracticeArea;
    payload.id=this.userForm.getRawValue().id;
    this.timeApproverRulesService.updateMapping(payload).subscribe((res: any) => {
      this.btnLoading=false;
      this.messageService.add({ severity: 'success', detail: 'Time approver rules have been saved successfully' });
      this.closeDialog();
    }, res => {
      this.btnLoading = false;
      this.messageService.add({ severity: 'error', detail: res.error.message });
    })
  }

}